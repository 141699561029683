import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { PerfumeAteilerStoreComponent } from './components/perfumeatelier/perfumeatelier.component';
import { Hi10Component } from './components/hi10/hi10';
import { Homepage2Component } from './components/homepage2/homepage2.component';
import { Homepage3Component } from './components/homepage3/homepage3.component';

const routes: Routes = [
  {
    path: 'i/:influencer',
    redirectTo: '/en/home',
  },
  {
    path: 'Ship-to-United-States',
    loadChildren: () =>
      import('./modules/booster/booster.module').then((m) => m.BoosterModule),
  },
  {
    path: 'ship-to-united-states',
    loadChildren: () =>
      import('./modules/booster/booster.module').then((m) => m.BoosterModule),
  },
  {
    path: ':lang/ship-to-united-states',
    loadChildren: () =>
      import('./modules/booster/booster.module').then((m) => m.BoosterModule),
  },

  {
    path: ':lang/i/:influencer',
    redirectTo: '/en/home',
  },
  {
    path: ':lang',
    redirectTo: '/en/home',
    pathMatch: 'full',
  },
  {
    path: ':lang/',
    redirectTo: '/en/home',
    pathMatch: 'full',
  },
  {
    path: ':lang/home',
    component: HomepageComponent,
  },
  {
    path: ':lang/welcome',
    component: Homepage2Component,
  },
  {
    path: ':lang/main',
    component: Homepage3Component,
  },
  {
    path: ':lang/signin',
    loadChildren: () =>
      import('./modules/signin/signin.module').then((m) => m.SigninModule),
  },
  {
    path: ':lang/signup',
    loadChildren: () =>
      import('./modules/signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: ':lang/register',
    loadChildren: () =>
      import('./modules/signup2/signup.module').then((m) => m.Signup2Module),
  },
  {
    path: ':lang/signup/:landingPage',
    loadChildren: () =>
      import('./modules/signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: ':lang/register/:landingPage',
    loadChildren: () =>
      import('./modules/signup2/signup.module').then((m) => m.Signup2Module),
  },
  {
    path: ':lang/member',
    loadChildren: () =>
      import('./components/dashboard/order/order.module').then(
        (m) => m.OrderModule
      ),
  },
  {
    path: ':lang/settings',
    loadChildren: () =>
      import('./components/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: ':lang/bfm',
    loadChildren: () =>
      import('./modules/bfm/bfm.module').then((m) => m.BfmModule),
  },
  {
    path: ':lang/campaigns',
    loadChildren: () =>
      import('./modules/campaigns/campaigns.module').then(
        (m) => m.CampaignsModule
      ),
  },
  {
    path: ':lang/ship-to/:country',
    loadChildren: () =>
      import('./modules/ship-to/ship-to.module').then((m) => m.ShipToModule),
  },
  {
    path: ':lang/pricing',
    loadChildren: () =>
      import(
        './modules/shipping-price-calculation/shipping-price-calculation.module'
      ).then((m) => m.ShippingPriceCalculationModule),
  },
  {
    path: ':lang/shipping-cost-calculator',
    loadChildren: () =>
      import(
        './modules/shipping-price-calculation/shipping-price-calculation.module'
      ).then((m) => m.ShippingPriceCalculationModule),
  },
  {
    path: ':lang/howitworks-for-shoppers',
    loadChildren: () =>
      import(
        './modules/howitworksforshoppers/howitworksforshoppers.module'
      ).then((m) => m.HowitworksforshoppersModule),
  },
  {
    path: ':lang/howitworks-for-hosts',
    loadChildren: () =>
      import('./modules/howitworksforhosts/howitworksforhosts.module').then(
        (m) => m.HowitworksforhostsModule
      ),
  },
  {
    path: ':lang/shop-from',
    loadChildren: () =>
      import('./modules/hosts/hosts.module').then((m) => m.HostsModule),
  },
  {
    path: ':lang/getaddress-host',
    loadChildren: () =>
      import('./modules/host-detail/host-detail.module').then(
        (m) => m.HostDetailModule
      ),
  },
  {
    path: ':lang/host',
    loadChildren: () =>
      import('./modules/host/host.module').then((m) => m.HostModule),
  },
  {
    path: ':lang/shorkstore',
    loadChildren: () =>
      import('./modules/influencer-store/influencer-store.module').then(
        (m) => m.InfluencerStoreModule
      ),
  },
  {
    path: ':lang/affiliate',
    loadChildren: () =>
      import('./modules/affiliate-store/affiliate-store.module').then(
        (m) => m.AffiliateStoreModule
      ),
  },
  {
    path: ':lang/perfumeatelier',
    component: PerfumeAteilerStoreComponent,
  },
  {
    path: ':lang/hi10',
    component: Hi10Component,
  },
  {
    path: ':lang/trust-safety',
    loadChildren: () =>
      import('./modules/trust-safety/trust-safety.module').then(
        (m) => m.TrustSafetyModule
      ),
  },
  {
    path: ':lang/shopper-details',
    loadChildren: () =>
      import('./modules/shopper-details/shopper-details.module').then(
        (m) => m.ShopperDetailsModule
      ),
  },
  {
    path: ':lang/terms',
    loadChildren: () =>
      import('./modules/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: ':lang/faq',
    loadChildren: () =>
      import('./modules/faq/faq.module').then((m) => m.FaqModule),
  },

  {
    path: ':lang/privacy-policy',
    loadChildren: () =>
      import('./modules/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },

  {
    path: ':lang/become-a-host',
    loadChildren: () =>
      import('./modules/become-a-host/become-a-host.module').then(
        (m) => m.BecomeAHostModule
      ),
  },
  {
    path: ':lang/become-an-affiliate',
    loadChildren: () =>
      import('./modules/become-an-affiliate/become-an-affiliate.module').then(
        (m) => m.BecomeAnAffiliateModule
      ),
  },
  {
    path: ':lang/b2b',
    loadChildren: () =>
      import('./modules/become-a-b2b-user/become-a-b2b-user.module').then(
        (m) => m.BecomeAB2BUserModule
      ),
  },
  {
    path: ':lang/host-funnel',
    loadChildren: () =>
      import('./modules/host-funnel/host-funnel.module').then(
        (m) => m.HostFunnelModule
      ),
  },
  {
    path: ':lang/verification',
    loadChildren: () =>
      import('./modules/verification/verification.module').then(
        (m) => m.VerificationModule
      ),
  },
  {
    path: ':lang/dashboard',
    loadChildren: () =>
      import('./modules/order-dashboard/order-dashboard.module').then(
        (m) => m.OrderDashboardModule
      ),
  },
  {
    path: ':lang/surveys',
    loadChildren: () =>
      import('./modules/survey/survey.module').then((m) => m.SurveyModule),
  },
  {
    path: ':lang/responsible-shipping',
    loadChildren: () =>
      import('./modules/responsible-shipping/responsible-shipping.module').then(
        (m) => m.ResponsibleShippingModule
      ),
  },
  {
    path: ':lang/shipping-standards',
    loadChildren: () =>
      import('./modules/shipping-standards/shipping-standards.module').then(
        (m) => m.ShippingStandardsModule
      ),
  },
  {
    path: ':lang/help',
    loadChildren: () =>
      import('./modules/help/help.module').then((m) => m.HelpModule),
  },
  {
    path: ':lang/prohibited-items',
    loadChildren: () =>
      import('./modules/prohibited-items/prohibited-items.module').then(
        (m) => m.ProhibitedItemsModule
      ),
  },
  {
    path: ':lang/about',
    loadChildren: () =>
      import('./modules/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: ':lang/contact-us',
    loadChildren: () =>
      import('./modules/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: ':lang/email-confirmation/:token',
    loadChildren: () =>
      import('./modules/email-confirmation/email-confirmation.module').then(
        (m) => m.EmailConfirmationModule
      ),
  },
  {
    path: ':lang/forgotmypassword/:token',
    loadChildren: () =>
      import(
        './modules/forgot-password-email/forgot-password-email.module'
      ).then((m) => m.ForgotPasswordEmailModule),
  },
  {
    path: ':lang/shipper-network',
    loadChildren: () =>
      import('./modules/shipper-network/shipper-network.module').then(
        (m) => m.ShipperNetworkModule
      ),
  },
  {
    path: ':lang/shop-from-us-to-uk',
    loadChildren: () =>
      import('./modules/ustouk-static/ustouk-static.module').then(
        (m) => m.UStoUKStaticModule
      ),
  },
  {
    path: ':lang/route',
    loadChildren: () =>
      import('./modules/marketing-module/marketing-module.module').then(
        (m) => m.MarketingModuleModule
      ),
  },
  {
    path: ':lang/service-agreement',
    loadChildren: () =>
      import('./modules/service-agreement/service-agreement.module').then(
        (m) => m.ServiceAgreementModule
      ),
  },
  {
    path: ':lang/HELLO10',
    loadChildren: () =>
      import('./modules/landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: ':lang/hello10',
    loadChildren: () =>
      import('./modules/landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: ':lang/shop/:pageURL',
    loadChildren: () =>
      import('./modules/landing-pages/landing-pages.module').then(
        (m) => m.LandingPagesModule
      ),
  },
  {
    path: ':lang/stores', loadChildren: () => import('./stores/stores.module').then(m => m.StoresModule)

  },{
    path: ':lang/shopnow', loadChildren: () => import('./modules/sales-funnel/sales-funnel.module').then(m => m.SalesFunnelModule)
  },
  {
    path: ':lang/shop-now', loadChildren: () => import('./modules/sales-funnel-test2/sales-funnel-test2.module').then(m => m.SalesFunnelTest2Module)
  },
  {
    path: ':lang/stores', loadChildren: () => import('./stores/stores.module').then(m => m.StoresModule)

  },
  // {
  //   path: ':lang/countries',
  //   loadChildren: () =>
  //     import('./lp-countries/modules/lp-countries.module').then(
  //       (m) => m.LPCountriesModule
  //     ),
  // },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'disabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
