<div class="trustpilot-widget trustpilot-widget-wrapper desktop" 
     [attr.data-locale]="'en-US'" 
     [attr.data-template-id]="desktopTemplateId" 
     [attr.data-businessunit-id]="'5911d3700000ff0005a2206a'" 
     [attr.data-style-height]="widgetDesktopHeight" 
     [attr.data-style-width]="'100%'">
</div>

<div class="trustpilot-widget trustpilot-widget-wrapper-mobile mobile" 
     [attr.data-locale]="'en-US'" 
     [attr.data-template-id]="mobileTemplateId" 
     [attr.data-businessunit-id]="'5911d3700000ff0005a2206a'" 
     [attr.data-style-height]="widgetMobileHeight" 
     [attr.data-style-width]="'100%'">
</div>